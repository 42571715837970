import { useState, useEffect } from 'react';

import { createComponent, For } from '@lib/util/templateHelpers';

import categoryApi from '@/apis/drupal/category';
import MenuList from '@/components/MenuList';

export default createComponent('CategoryMenuList', {}, function CategoryMenuList ({}, props) {
  const [ categories, setCategories ] = useState(props.categories || []);

  if (!props.categories) {
	useEffect(() => {
		(async () => {
		  try {
			const categories = await categoryApi.listAllWithThumbnail();
			setCategories(categories || []);
		  } catch (error) {
			console.error('Error fetching categories:', error);
		  }
		})();
	  }, []);
  }
  
  return (
    <MenuList>
      <MenuList.Item href='/featured'><img src="/images/category-icons/iogames_ico_featured.png" height="24" width="24" alt="Featured Games" /> Featured</MenuList.Item>
      <MenuList.Item href='/new'><img src="/images/category-icons/iogames_ico_new.png" height="24" width="24" alt="Featured Games" /> New</MenuList.Item>
      <MenuList.Item href='/popular'><img src="/images/category-icons/iogames_ico_popular.png" height="24" width="24" alt="Popular Games" /> Popular</MenuList.Item>
      {/* <MenuList.Item href='/top-rated'>Top</MenuList.Item> */}
      {
        For(categories, (category) => (
          <MenuList.Item key={category.id} href={category.path}>
			{category.thumbnailUrl && (
				<img src={category.thumbnailUrl} height={24} width={24} alt={category.name} />
			)}
			{category.name}</MenuList.Item>
        ))
      }
    </MenuList>
  );
});
